@import 'styles/layers.scss';

@layer uikit {
  .menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    padding: 13px 20px;
    &:active {
      background: transparent;
    }
  }
  
  .dark {
    &.menu-item {
      color: var(--white);
      &:hover {
        background: var(--black3);
      }
    }
  }
  
  .icon {
    color: var(--white);
  }
  
  .opacity {
    opacity: 0.3;
  }
}